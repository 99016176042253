import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, smallText } from '../../../../styles/dimensions';
import content from '../../../../content/showcase/enzkreis/views.json';

const Views = () => {
	const isSmall = useMediaQuery(useTheme().breakpoints.down(1232));

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<div className={classes.container} data-scroll data-scroll-speed="2">
				<Grid container spacing={isSmall ? 2 : 6} classes={{ root: classes.imageGrid }}>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/enzkreis/bad-signal.png"
							alt="Bad signal preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/enzkreis/location-not-found.png"
							alt="Location not found preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/enzkreis/location-found.png"
							alt="Location found preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
						/>
					</Grid>
				</Grid>

				<Grid container spacing={isSmall ? 2 : 6}>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/enzkreis/shorter_list.png"
							alt="Disposal locations list preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/enzkreis/disposal-location.png"
							alt="Disposal location preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<h5 className={classes.goalTitle}>{content.goal.title}</h5>
						<p className={classes.goalDescription}>{content.goal.description}</p>
					</Grid>
				</Grid>
			</div>

			<StaticImage
				src="../../../../images/showcase/enzkreis/showcase_enzkreis.jpg"
				alt="Enzkreis showcase"
				layout="fullWidth"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="50% 50%"
				className={classes.showcaseImage}
			/>
		</section>
	);
};

export default Views;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.backgroundDarkGray,
		'@media (max-width: 599px)': {
			paddingTop: '4em',
		},
	},
	container: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media(max-width: 1231px)': {
			padding: '0 1em',
		},
	},
	imageGrid: {
		marginBottom: '6.75em',
		'@media (max-width: 599px)': {
			marginBottom: '2em',
		},
	},
	image: {
		'@media (max-width: 599px)': {
			marginBottom: '2em',
		},
	},
	goalGrid: {
		marginBottom: '6.75em',
		'@media (max-width: 599px)': {
			marginBottom: '4em',
		},
	},
	goalTitle: {
		letterSpacing: '0.075em',
		color: colors.white,
		fontFamily: Rigraf.regular,
		marginTop: 0,
		marginBottom: '1.5em',
		'@media (max-width: 599px)': {
			marginTop: '2em',
		},
	},
	goalDescription: {
		...smallText,
		color: colors.careersJobGray,
		fontFamily: MetroSans.book,
	},
	showcaseImage: {
		maxHeight: '100vh',
		marginTop: '2.25em',
		'@media (max-width: 599px)': {
			marginTop: '4em',
		},
	},
});
