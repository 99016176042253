import Layout from '../../components/layout';
import Views from '../../components/sections/showcase/enzkreis/views';
import IntroLayout from '../../components/sections/showcase/introLayout';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import content from '../../content/showcase/enzkreis/intro.json';
// @ts-ignore
import video from '../../videos/enzkreis.mp4';

const Enzkreis = () => (
	<Layout>
		<SEO title={titles.showcase.enzkreis} description={descriptions.showcase.enzkreis} />

		<IntroLayout content={content} devices={{ mobile: true }} video={video} scaleMobile />
		<Views />
	</Layout>
);

export default Enzkreis;
